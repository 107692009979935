import React, {useState, useRef} from 'react'
import axios from 'axios';
import { Constants } from '../../Constants';
import { toast} from 'react-toastify'
import qs from 'qs'

function ResetPassword() {
    const [pass1Visible, setPass1Visible] = useState(false)
    const [pass2Visible, setPass2Visible] = useState(false)
    const [pass3Visible, setPass3Visible] = useState(false)
    const pass1 = useRef("")
    const pass2 = useRef("")
    const pass3 = useRef("")
    const resetPassword = (e)=>{
        e.preventDefault()
        e.stopPropagation()
        const data2 =qs.stringify({
            old_password:pass1.current.value,
            new_password1:pass2.current.value,
            new_password2: pass3.current.value,
            action: "password"
        })
        const data = new  FormData()
        data.append('action', "password");  
        data.append('old_password', pass1.current.value);  
        data.append('new_password1', pass2.current.value);  
        data.append('new_password2', pass3.current.value);  
        const config = {
            method: "post",
            url: Constants.BASE_URL + "/api/v2/account/manage/",
            data,
            headers:{
              
                "User-Agent":" PostmanRuntime/7.26.10",
                "Accept": "*/*",
                
                
            }
        }

        axios( config).then(response=>{
            const {data} = response
            if(data.error === 1){
                toast.error(data.message)
            }else{
                toast.success(data.message)
            }
        }).catch(error=>{
            console.log(error);
            
            toast.error("با خطا مواجه شد")
        })
    }
    return (
        <div className="card reset-password">
            <div className="card-header">
                <h4 className="card-title">تغییر رمز عبور</h4>
            </div>
            <div className="card-body">
                <form action="#" onSubmit={resetPassword}>
                    <div className="row">
                        <div className="mb-3 col-xl-12 reset-row">
                            <label className="form-label">رمز عبور کنونی</label>
                            <input ref={pass1} type={pass1Visible? "text": "password"} className="form-control" />
                            <span className={"visible icofont-" + (pass1Visible? "eye-blocked": "eye")} onClick={e=>setPass1Visible(s=>!s)} ></span>
                        </div>
                        <div className="mb-5 col-xl-12 reset-row">
                            <label className="form-label">رمز جدید</label>
                            <input ref={pass2} type={pass2Visible? "text": "password"} className="form-control"  />
                            <span className={"visible icofont-" + (pass2Visible? "eye-blocked": "eye")} onClick={e=>setPass2Visible(s=>!s)} ></span>
                            <small className='form-text form-help text-danger position-absolute pt-1 d-flex align-items-center' style={{fontSize: "11px"}}><i class="bi bi-exclamation-triangle">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle" viewBox="0 0 16 16">
                                <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
                                <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
                            </svg></i> 
                            <span className='mx-2'>شامل حروف بزرگ، کوچک ، اعداد و کاراکترهای خاص مثل: $</span>
                            </small>
                        </div>
                        <div className="mb-3 col-xl-12 reset-row">
                            <label className="form-label">تکرار رمز جدید</label>
                            <input ref={pass3} type={pass3Visible? "text": "password"} className="form-control" />
                            <span  className={"visible icofont-" + (pass3Visible? "eye-blocked": "eye")} onClick={e=>setPass3Visible(s=>!s)} ></span>
                        </div>
                        <div className="col-12  text-start">
                            <button className="btn btn-success waves-effect">تغییر پسورد</button>
                        </div>
                    </div>
                </form>
            </div>
          
        </div>
    )
}

export default ResetPassword
