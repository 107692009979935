import React from 'react'

function Input({onChange, extra=false, ...rest}) {
    const p2e = s => s.replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d))
    const filter = (e)=>{
        const val = p2e(String(e.target.value)).replace(/[^0-9\.]/g,"")
        if(!extra)
          onChange(val)
        else
          onChange(val, 0, 0)

    }
  return (
    <input {...rest} onChange={filter}/>
  )
}

export default Input