import React from 'react'
import {Link} from 'react-router-dom'
function S404() {
    return (
        <main id="S404">
            

            <h2>صفحه یافت نشد</h2>
            <Link to="/">داشبورد</Link>

    </main>
    )
}

export default S404
