import React, { } from 'react';
import { Link } from 'react-router-dom';
import VideoModal from './VideoModal';
// import { Row, Col, Card } from 'react-bootstrap'



function SettingsNav() {

    return (
        <>
            <div className="card settings_menu">
                <div className="card-header d-flex align-items-center justify-content-between">
                    <h4 className="card-title pt-1">تنظیمات</h4>
                    <div style={{fontSize: 12}}>
                        راهنمای احراز هویت 
                        {"  "}
                        <VideoModal videoSrc={"http://dl.hi-exchange.com//videos/Training/ehrazhoviyat.mp4"}/>
                    </div>
                </div>
                <div className="card-body">
                    <ul>
                        <li className="nav-item">
                            <Link to={'./settings'} className="nav-link active">
                                <i className="la la-user"></i>
                                <span>ویرایش پروفایل</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'./settings-preferences'} className="nav-link">
                                <i className="la la-cog"></i>
                                <span>شخصی سازی</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'./settings-security'} className="nav-link">
                                <i className="la la-lock"></i>
                                <span>امنیت</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'./settings-account'} className="nav-link">
                                <i className="la la-university"></i>
                                <span>افزودن حساب بانکی</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'./settings-invite'} className="nav-link">
                                <i className="la la-user-friends"></i>
                                <span>دعوت دوستان</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default SettingsNav;