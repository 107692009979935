import React from 'react'
const p2e = s => s.replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d))
function irt(num) {
    const t = Number(Number(p2e(String(num).replace(/٬/g, ''))).toFixed()).toLocaleString('fa-IR')
    return t
}

function rnd(num, us = false) {
    let num2 = String(num)
        .replace(/,/g, '')
        .replace(/،/g, '')
        .replace(/٬/g, '')
    num2 = p2e(num2)
    if (us) return num2

    return String(num).indexOf("٬") > -1 ? irt(Math.round(Number(num2) * 1000) / 1000) : Math.round(Number(num2) * 1000) / 1000
}
function ScheduleItem({ item, currencyList, openCancelModal }) {
    let status = ""
    switch (item.status) {
        case "accepted": status = "سفارش باز"; break;
        case "canceled": status = "لغو شده"; break;
        case "delivered": status = "با موفقیت انجام شد"; break;
        case "deposited": status = "با موفقیت انجام شد"; break;
        case "unpaid": status = "با موفقیت انجام شد"; break;
        case "suspended": status = "مشکل در ثبت سفارش"; break;
        case "pending": status = "در حال بررسی توسط کارشناس"; break;
        default: status = "در حال بررسی "
    }
    const s = currencyList && currencyList.filter(i => i && i.id === item.source_asset)[0]
    const d = currencyList && currencyList.filter(i => i && i.id === item.destination_asset)[0]
    console.log(item.status)
    return (

        <div className="border rounded mb-1 p-2 position-relative">
            <h6>
                <span>شماره سفارش: {item.id} </span>
                <span className="mx-4" dir="ltr">تاریخ سفارش: {new Date(item.published).toLocaleTimeString('fa',)} {" "}{new Date(item.published).toLocaleDateString('fa',)}  </span>
            </h6>
            {item.type === "buy" ? <div style={{ "fontSize": "15px" }}>
                {
                    item.status === 'delivered' ?
                        <>
                            {"خرید "} < small className="text-primary">{rnd(item.source_amount)} {" "}{d.name}</small>
                            {" در بازار "} <small className="text-primary">{s.name}</small>
                            {" در قیمت "} <small className="text-primary px-2">{item.destination_amount}</small>{s.name}
                            {""} <small className="text-primary">{status}</small>
                        </> : <>
                            {"خرید "} <small className="text-primary">{rnd(item.destination_amount)} {" "}{d.name}</small>
                            {" در بازار "} <small className="text-primary">{s.name}</small>
                            {" در قیمت "} <small className="text-primary px-2">{item.source_amount / item.destination_amount}</small>{s.name}
                            {" معادل  "} <small className="text-primary">{rnd(item.source_amount)} {" "} {s.name}</small><br />
                            {" وضعیت: "} <small className="text-primary">{status}</small>
                        </>
                }
            </div>
                : <div style={{ "fontSize": "15px" }}>
                    {
                        item.status === 'delivered' ?
                            <>
                                {" فروش "} <small className="text-primary">{item.source_amount} {" "}{s.name}</small>
                                {" در بازار "} <small className="text-primary">{d.name}</small>
                                {" در قیمت "} <small className="text-primary">{(item.destination_amount)}  </small>  {d.name}
                                {""} <small className="text-primary">{status}</small>
                                
                            </>
                            : <>
                                {" فروش "} <small className="text-primary">{item.source_amount} {" "}{s.name}</small>
                                {" در بازار "} <small className="text-primary">{d.name}</small>
                                {" در قیمت "} <small className="text-primary">{rnd(item.destination_amount / item.source_amount)}  </small>  {d.name}
                                {" معادل  "} <small className="text-primary">{rnd(item.destination_amount)} {" "} {d.name}</small><br />
                                {" وضعیت: "} <small className="text-primary">{status}</small>
                            </>
                    }
                </div>
            }
            {
                item.status === "accepted" ?
                    <div style={{ cursor: "pointer" }} className="text-danger position-absolute p-2 start-0 bottom-0">
                        <small onClick={e => openCancelModal(item.id)}>لغو سفارش</small>
                    </div>
                    : item.status === "suspended" ?
                        <small className="text-info" style={{ fontSize: "11px" }}>درصورت عدم لغو سفارش در ٢ ساعت آینده، با پشتیبانی در ارتباط باشید</small>
                        : null
            }

        </div >
    )
}

export default ScheduleItem