import React, { useState, useEffect, useRef } from 'react';
import { Tab, Tabs, Form, Popover, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PageTitle from '../element/page-title';
import Header2 from '../layout/header2';
import Sidebar from '../layout/sidebar';
import { useSelector, useDispatch } from 'react-redux';
import { creating_order, create_order, create_schedule, cancel_schedule } from '../../redux/actions';
import qs from 'qs'
import axios from 'axios';
import { toast } from 'react-toastify'
import Loader from 'react-loader-spinner'
import { Constants } from '../../Constants';
import Chart from '../element/chart'
import Timer from '../element/Timer'
import IRTChart from '../charts/IRTChart'
import Price from '../element/Price'
import Input from '../element/Input'
import { useDebouncedCallback } from 'use-debounce';
import DebounceTimer from '../element/DebounceTimer';
import ScheduleItem from '../element/ScheduleItem';

const p2e = s => s.replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d))
function irt(num) {
    const t = Number(Number(p2e(String(num).replace(/٬/g, ''))).toFixed()).toLocaleString('fa-IR')
    return t
}

function compute_fee(unit, unit_price, source_amount, subfrom = undefined) {

    if (!unit_price || source_amount.length === 0) return 0
    unit_price = String(unit_price || "").replace(/,/g, '');
    source_amount = String(source_amount || "").replace(/,/g, '');
    const tot = Number(unit_price) * Number(source_amount)
    if (isNaN(tot)) return 0
    if (subfrom) {
        return subfrom - tot
    }
    return tot
}
// for sale
function compute_fees(unit, unit_price, source_amount, subfrom = undefined) {

    if (!unit_price || source_amount.length === 0) return 0
    unit_price = String(unit_price || "").replace(/,/g, '');
    source_amount = String(source_amount || "").replace(/,/g, '');
    const tot = Number(source_amount) / Number(unit_price)

    if (isNaN(tot)) return 0
    if (subfrom) {
        return subfrom - tot
    }
    return tot
}

function BuySell() {
    const dispatch = useDispatch()
    const [buyTimer, setBuyTimer] = useState(false)
    const [buyScheduleTimer, setBuyScheduleTimer] = useState(false)
    const [sellTimer, setSellTimer] = useState(false)

    const [isComputing, setIsComputing] = useState(false)
    const { currencyList } = useSelector(state => state.currencies)
    const { schedules, creating_schedule } = useSelector(state => state.accounts)
    const wallet = useSelector(state => state.wallet.wallet)
    const _creating_order = useSelector(state => state.accounts.creating_order)
    const [tab, setTab] = useState("buy")
    const [chartOpen, setChartOpen] = useState(true)
    const [buyConvertAmount, setBuyConvertAmount] = useState(0)
    const [sellConvertAmount, setSellConvertAmount] = useState(0)
    const [buyConvertInvalid, setBuyConvertInvalid] = useState(0)
    const [sellConvertInvalid, setSellConvertInvalid] = useState(0)
    const [buySource, setBuySource] = useState({})

    // Schedule
    const [buySchedulePrice, setBuySchedulePrice] = useState(0)
    const [sellSchedulePrice, setSellSchedulePrice] = useState(0)
    const [lastChartName, setLastChartName] = useState()
    const [isScheduledBuy, setIsScheduledBuy] = useState(false)
    const [buyScheduleAmount, setBuyScheduleAmount] = useState(0)
    const [isScheduledSell, setIsScheduledSell] = useState(false)
    const [sellScheduleAmount, setSellScheduleAmount] = useState(0)
    const [sellDestination, setSellDestination] = useState({})
    const [cancelScheduleID, setCancelSceduleID] = useState(undefined)
    const [showCancelModal, setShowCancelModal] = useState(undefined)

    const sellConvertErrorMessage = useRef("")
    const buyConvertErrorMessage = useRef("")

    const sellAvailableCurrencyR = useRef(0)
    const buyAvailableCurrencyR = useRef(0)
    // const buySourceR = useRef({small_name_slug: undefined})
    const sellSourceR = useRef({ small_name_slug: undefined })

    const [buyDestination, setBuyDestination] = React.useState({})


    const buyLowCreditR = useRef(false)
    const sellLowCreditR = useRef(false)

    const buyConversionResultR = useRef(0)
    const buyConversionResultStrR = useRef(0)
    const buyEndPriceR = useRef(0)
    const buyKarmozdAmountR = useRef(0)
    const buyFixedKarmozdR = useRef(0)
    const buyTransactionFee = useRef(0)

    const buyTotalR = useRef(0)
    const sellConversionResultR = useRef(0)
    const sellConversionResultStrR = useRef(0)
    const sellEndPriceR = useRef(0)
    const sellKarmozdAmountR = useRef(0)
    const sellTransactionFee = useRef(0)
    const sellFixedKarmozdR = useRef(0)
    const sellTotalR = useRef(0)


    // Chart
    const [lastTab, setLastTab] = React.useState("buy")

    const buyUnitPrice = useRef(0)
    const buyFeeUnit = useRef("")
    const buyFeeUnitEqual = useRef("")
    const buyFixedFeeEqual = useRef(0)
    const buyVariableFeeEqual = useRef(0)
    const buyTotalFeeEqual = useRef(0)
    const buyFinalValue = useRef(0)
    const buyFinalValueEqual = useRef(0)
    const buyBuyerAmount = useRef(0)

    const sellUnitPrice = useRef(0)
    const sellFeeUnit = useRef("")
    const sellFeeUnitEqual = useRef("")
    const sellFixedFeeEqual = useRef(0)
    const sellVariableFeeEqual = useRef(0)
    const sellTotalFeeEqual = useRef(0)
    const sellFinalValue = useRef(0)
    const sellFinalValueEqual = useRef(0)
    const sellBuyerAmount = useRef(0)

    const handleBuyConfirm = () => {
        if (isComputing) return false
        if (isScheduledBuy) {
            dispatch(create_schedule({
                pair: buySource.id,
                asset: buyDestination.id,
                amount: buyScheduleAmount,
                price: buySchedulePrice,
                type: "buy"
            })).then(({ result, message }) => {

                if (result === "success")
                    toast.success(message)
                else
                    toast.error(message)
            }).catch(err => {
                console.log(err);
                toast.error("خطا در ثبت سفارش")
            })
        } else {


            const _wallet = wallet && wallet.length && wallet.find(item => item && item.service && item.service.id === buySource.id) ?
                wallet.find(item => item && item.service && item.service.id === buySource.id).id : undefined

            const data = {
                source_price: +(String(buyConversionResultStrR.current).replace(",", "")) + (+buyKarmozdAmountR.current),
                destination_price: String(buyConvertAmount),
                source_asset: String(buySource.id),
                destination_asset: String(buyDestination.id),
                wallet: _wallet,
                description: "",
                type: "buy"
            }
            dispatch(creating_order(true))
            dispatch(create_order(data, toast))
        }
    }
    const handleSellConfirm = () => {
        if (isComputing) return false;
        if (isScheduledSell) {
            dispatch(create_schedule({
                asset: sellSourceR.current.id,
                pair: sellDestination.id,
                amount: sellScheduleAmount,
                price: sellSchedulePrice,
                type: "sell"
            })).then(({ result, message }) => {
                if (result === "success")
                    toast.success(message)
                else
                    toast.error(message)
            }).catch(err => {
                console.log(err);
                toast.error("خطا در ثبت سفارش")
            })
        } else {
            dispatch(creating_order(true))
            const _wallet = wallet && wallet.length ?
                wallet.filter(item => item && item.service && item.service.id === buySource.id).lenght ?
                    wallet.filter(item => item && item.service && item.service.id === buySource.id)[0].id : undefined : undefined
            const data = {
                source_price: String(sellConvertAmount),
                destination_price: sellConversionResultStrR.current,
                source_asset: String(sellSourceR.current.id),
                destination_asset: String(sellDestination.id),
                wallet: _wallet,
                changed: "source",
                description: "",
                type: "sell"
            }
            dispatch(create_order(data, toast))
        }
    }

    const get_available = (symbolid) => {
        if (!wallet || !wallet.length) return 0
        const target = wallet.filter((item, i) => {
            return item && item["service"] && item["service"]["id"] === symbolid
        })
        return target.length > 0 ? target[0]["balance"] : 0
    }

    const changeBuySource = (e, selectedCurrency) => {
        selectedCurrency = currencyList && currencyList.filter((c, idx) => c.id === +selectedCurrency)[0]
        let av = get_available(selectedCurrency.id)
        buyAvailableCurrencyR.current = av
        buyLowCreditR.current = false
        setBuyConvertAmount(0)
        setBuyScheduleAmount(0)
        setBuySchedulePrice(0)

        setBuySource(selectedCurrency);
        computePrices({ buyConvertAmountP: 0 })
    }
    const changeBuyDestination = (e) => {
        let selectedCurrency = e.target.value;
        if (!selectedCurrency || selectedCurrency.indexOf("انتخاب") > -1) return;
        selectedCurrency = currencyList && currencyList.filter((c, idx) => c.id === +selectedCurrency)[0]
        setBuyDestination(selectedCurrency)
        buyLowCreditR.current = false
        setBuyConvertAmount(0)
        changeLastChartName(selectedCurrency.small_name_slug)
        computePrices({ buyConvertAmountP: 0 })

    }
    const changeSellDestination = (e, selectedCurrency) => {
        if (!currencyList.length) return
        selectedCurrency = currencyList && currencyList.filter(c => c.id === +selectedCurrency)[0]
        sellLowCreditR.current = false
        setSellDestination(selectedCurrency)
        setSellConvertAmount(0)
        setSellScheduleAmount(0)
        setSellSchedulePrice(0)
        changeLastChartName(selectedCurrency.small_name_slug)
        computePrices({ sellConvertAmountP: 0 })
    }
    const changeSellSource = (e) => {
        let selectedCurrency = e.target.value;
        if (!selectedCurrency || selectedCurrency.indexOf("انتخاب") > -1) {
            sellSourceR.current = undefined
            return;
        }
        selectedCurrency = currencyList && currencyList.filter((c, idx) => c.id === +selectedCurrency)[0]
        let av = get_available(selectedCurrency.id)
        sellSourceR.current = selectedCurrency;
        sellAvailableCurrencyR.current = av
        sellLowCreditR.current = false
        setSellConvertAmount(0)
        changeLastChartName(selectedCurrency.small_name_slug)
        computePrices({ sellConvertAmountP: 0 })
    }
    const changeLastChartName = (name) => {
        setLastChartName(name)
        dispatch({ type: "UPDATE_LAST_CHART_NAME", payload: name })
    }
    const filterTimeout = React.useRef()
    const changeBuyAmount = (value, convertAll = false) => {
        clearTimeout(filterTimeout.current)
        if (value) setBuyTimer(1)
        
        filterTimeout.current = setTimeout(() => {
            computePrices({ buyConvertAmountP: value, buyConvertAll: convertAll })
            setBuyConvertAmount(value)
            setBuyTimer(0)
        }, (1000))
    }

    const filterTimeoutSchedule = React.useRef()
    const _changeBuyScheduleAmount = (value, convertAll = false) => {
        clearTimeout(filterTimeoutSchedule.current)
        if (value) setBuyScheduleTimer(1)
        filterTimeoutSchedule.current = setTimeout(() => {
            changeBuyScheduleAmount(value, convertAll)
            setBuyScheduleTimer(0)
            changeSceduleBuyAmountForced(value)
        }, (1000))

    }

    const sellTimeout = React.useRef()
    const changeSellAmount = (value) => {
        console.log(value)
        clearTimeout(sellTimeout.current)
        if (value) setSellTimer(1)
        sellTimeout.current = setTimeout(() => {
            computePrices({ sellConvertAmountP: value })
            setSellConvertAmount(value)
            setSellTimer(0)
        }, (1000))

    }

    const changeBuyScheduleAmount = (value, convertAll) => {
        console.log(value)
        computePrices({ buyConvertAmountP: value, buyConvertAll: convertAll })
        setBuyScheduleAmount(value)
    }

    const changeBuySchedulePrice = (value) => {
        computePrices({ buyConvertAmountP: buyScheduleAmount, buySchedulePriceP: value })
        setBuySchedulePrice(value)
    }

    const changeSellScheduleAmount = (value) => {
        computePrices({ sellConvertAmountP: +value })
        setSellScheduleAmount(value)
    }
    const changeSellSchedulePrice = (value) => {
        computePrices({ sellConvertAmountP: sellScheduleAmount, sellSchedulePriceP: value })
        setSellSchedulePrice(value)
    }


    const computePrices = ({
        buyConvertAmountP = buyConvertAmount,
        sellConvertAmountP = sellConvertAmount,
        buySchedulePriceP = buySchedulePrice,
        sellSchedulePriceP = sellSchedulePrice,
        buyConvertAll = undefined
    }) => {
        if (tab === "buy") {
            if (buyConvertAmountP && String(buyConvertAmountP).indexOf('.') === String(buyConvertAmountP).length - 1) return
            if (!buyConvertAmountP && !buyConvertAll) {
                buyEndPriceR.current = 0
                buyKarmozdAmountR.current = 0
                buyFixedKarmozdR.current = 0
                buyTransactionFee.current = 0
                buyConversionResultR.current = 0
                buyConversionResultStrR.current = 0
                buyTotalR.current = 0
                buyLowCreditR.current = false
                buyConvertErrorMessage.current = ""

                setBuyConvertInvalid(true)
                return

            }
            setIsComputing(true)
            const data = qs.stringify({
                'source': String(buySource.id),
                'destination': String(buyDestination.id),
                'changed': !buyConvertAll ? 'destination' : 'source',
                'source-price': (buyConvertAll && buyConvertAmountP) || '0',
                'destination-price': !buyConvertAll ? buyConvertAmountP : '0'
            })
            axios.post(Constants.BASE_URL + "/api/v2/order/calculator/", data, {
                headers: {
                    "Content-type": "application/x-www-form-urlencoded"
                }
            }).then(response => {
                if (!response) throw Error("no resp")
                const { data } = response
                if (data.message) {
                    buyConvertErrorMessage.current = data.message
                } else
                    buyConvertErrorMessage.current = ""
                buyConvertAmountP = !buyConvertAll ? +data["destination_price"] : buyConvertAmountP
                const prec2 = Math.max(8, +data["source_decimal"], +data["destination_decimal"])

                buyEndPriceR.current = Math.round(Math.pow(10, prec2) * +data["unit_price"]) / Math.pow(10, prec2)
                buyKarmozdAmountR.current = +(data["total_fee"]) || 0
                buyTransactionFee.current = +data["fee"] || 0
                buyFixedKarmozdR.current = +data["fix_fee"] || 0
                buyUnitPrice.current = isScheduledBuy ? buySchedulePriceP : (data['unit_price'])
                buyConversionResultR.current = buyConvertAmountP ? +data['unit_price'] * buyConvertAmountP + buyKarmozdAmountR.current : 0
                buyConversionResultStrR.current = buyConvertAmountP ? +data['unit_price'] * buyConvertAmountP + buyKarmozdAmountR.current : 0
                const a = buyConversionResultR.current
                const a2 = buyUnitPrice.current * buyKarmozdAmountR.current

                buyFeeUnit.current = buySource.name
                buyFeeUnitEqual.current = buyDestination.name
                buyFixedFeeEqual.current = compute_fees(buyDestination.small_name_slug, buyUnitPrice.current, buyFixedKarmozdR.current)
                buyVariableFeeEqual.current = compute_fees(buyDestination.small_name_slug, buyUnitPrice.current, buyTransactionFee.current)
                buyTotalFeeEqual.current = compute_fees(buyDestination.small_name_slug, buyUnitPrice.current, buyKarmozdAmountR.current)
                buyFinalValueEqual.current = buyConversionResultR.current - buyKarmozdAmountR.current
                buyFinalValue.current = Number(buyConvertAmountP)
                buyUnitPrice.current = data['unit_price']



                buyTotalR.current = (a + a2).toLocaleString()
                console.log(buySource)
                if (buySource.small_name_slug === 'IRT') {
                    buyConversionResultStrR.current = Number((buyConversionResultStrR.current).toFixed(0)).toLocaleString('fa')
                }
                if (data.message && data.message.indexOf("مجاز") !== -1) {
                    buyConversionResultR.current = "-"
                    buyConversionResultStrR.current = "-"
                }
                buyBuyerAmount.current = buyConvertAmountP
                if (buyConvertAll) {
                    buyConversionResultR.current = +data['source_price']
                    buyConversionResultStrR.current = data['source_price_str']
                    buyFinalValue.current = data["destination_price"]
                    buyBuyerAmount.current = data["destination_price"]
                    changeBuyAmountForced(data["destination_price"])
                    buyLowCreditR.current = +buyConversionResultR.current > +buyAvailableCurrencyR.current
                } else {
                    buyLowCreditR.current = +buyConversionResultR.current > +buyAvailableCurrencyR.current

                }

                if (isScheduledBuy) {
                    buyFinalValueEqual.current = buyConvertAmountP * buySchedulePriceP - buyKarmozdAmountR.current
                    buyFinalValue.current = compute_fees(buySource.small_name_slug, buySchedulePriceP, buyFinalValueEqual.current)
                    buyLowCreditR.current = buyConvertAmountP * buySchedulePrice > +buyAvailableCurrencyR.current
                    buyBuyerAmount.current = buyConvertAmountP
                }



            }).catch(error => {
                console.log(error);
            })
                .finally(f => {
                    setIsComputing(false)
                })
        } else {
            if (sellConvertAmountP && String(sellConvertAmountP).indexOf('.') === String(sellConvertAmountP).length - 1) return

            if (!sellConvertAmountP || String(sellDestination.id) === String(sellSourceR.current.id)) {
                sellEndPriceR.current = 0
                sellKarmozdAmountR.current = 0
                sellFixedKarmozdR.current = 0
                sellTransactionFee.current = 0
                sellConversionResultR.current = 0
                sellConversionResultStrR.current = 0
                sellTotalR.current = 0
                sellLowCreditR.current = false
                sellConvertErrorMessage.current = ""
                setSellConvertInvalid(true)
                return

            }
            setIsComputing(true)
            const data = qs.stringify({
                'source': String(sellSourceR.current.id),
                'destination': String(sellDestination.id),
                'changed': 'source',
                'source-price': sellConvertAmountP,
                'destination-price': '0'
            })
            axios.post(Constants.BASE_URL + "/api/v2/order/calculator/", data, {
                headers: {
                    "Content-type": "application/x-www-form-urlencoded"
                }
            }).then(response => {
                if (!response) throw Error("no resp")
                const { data } = response

                if (data.message) {
                    sellConvertErrorMessage.current = data.message
                } else
                    sellConvertErrorMessage.current = ""



                const prec2 = Math.max(8, +data["source_decimal"], +data["destination_decimal"])

                sellEndPriceR.current = Math.round(Math.pow(10, prec2) * +data["unit_price"]) / Math.pow(10, prec2)
                sellFixedKarmozdR.current = +data["fix_fee"] || 0
                sellTransactionFee.current = +data["fee"] || 0
                sellKarmozdAmountR.current = +data["total_fee"] || 0
                sellConversionResultR.current = sellConvertAmountP ? +data["destination_price"] : 0
                sellConversionResultStrR.current = sellConvertAmountP ? data["destination_price_str"] : 0
                sellUnitPrice.current = data['unit_price']
                const a = sellConversionResultR.current
                // const a2 = sellUnitPrice.current * sellKarmozdAmountR.current
                const a2 = sellKarmozdAmountR.current
                sellFeeUnit.current = sellDestination.name
                sellFeeUnitEqual.current = sellSourceR.current.small_name_slug
                sellFixedFeeEqual.current = compute_fees(sellSourceR.current.small_name_slug, sellUnitPrice.current, sellFixedKarmozdR.current)
                sellVariableFeeEqual.current = compute_fees(sellSourceR.current.small_name_slug, sellUnitPrice.current, sellTransactionFee.current)
                sellTotalFeeEqual.current = compute_fees(sellSourceR.current.small_name_slug, sellUnitPrice.current, sellKarmozdAmountR.current)
                sellFinalValue.current = sellConvertAmountP - sellKarmozdAmountR.current
                sellFinalValueEqual.current = Number(sellConversionResultR.current) - compute_fee(sellSourceR.current.small_name_slug, sellUnitPrice.current, sellKarmozdAmountR.current)

                if (sellSourceR.current.id === Constants.IRT_CURRENCY_ID && sellDestination.name.indexOf("تتر") > -1) {
                    sellFixedFeeEqual.current = sellFixedKarmozdR.current / +sellUnitPrice.current
                    sellVariableFeeEqual.current = (sellTransactionFee.current / +sellUnitPrice.current)
                    sellTotalFeeEqual.current = sellKarmozdAmountR.current / +sellUnitPrice.current
                    sellFinalValue.current = sellConvertAmountP - (sellKarmozdAmountR.current / sellDestination.show_price_irt)
                    // sellFinalValueEqual.current = irt(sellFinalValue.current  * sellDestination.show_price_irt)
                    sellFeeUnit.current = sellSourceR.current.name
                    sellFeeUnitEqual.current = sellDestination.small_name_slug
                    // sellFixedKarmozdR.current = irt(sellFixedKarmozdR.current)
                    // sellTransactionFee.current = irt(sellTransactionFee.current)
                    // sellKarmozdAmountR.current = irt(sellKarmozdAmountR.current)
                }

                if (sellDestination.small_name === "IRT" && !isScheduledSell) {
                    let v = a + sellKarmozdAmountR.current
                    sellTotalR.current = Number(Number(v).toFixed()).toLocaleString()
                    // sellTransactionFee.current = Number(Number(sellTransactionFee.current).toFixed()).toLocaleString()
                    // sellUnitPrice.current = sellUnitPrice.current
                } else {
                    sellTotalR.current = (a + a2).toLocaleString()
                }
                sellBuyerAmount.current = sellConvertAmountP

                if (isScheduledSell) {
                    sellFinalValueEqual.current = sellConvertAmountP - sellKarmozdAmountR.current
                    sellFinalValue.current = compute_fee(sellSourceR.current.small_name_slug, sellSchedulePriceP, sellFinalValueEqual.current)
                    sellLowCreditR.current = sellConvertAmountP > +sellAvailableCurrencyR.current
                } else {
                    sellLowCreditR.current = sellConvertAmountP > +sellAvailableCurrencyR.current
                }
            }).catch(error => {
                console.log(error);
            })
                .finally(f => {
                    setIsComputing(false)
                })
        }
    }
    useEffect(() => {
        if (!buySource.id && currencyList.length > 0) changeBuySource('', Constants.USDT_CURRENCY_ID)
        if (!sellDestination.id && currencyList.length > 0) changeSellDestination('', Constants.USDT_CURRENCY_ID)
        setBuyConvertAmount("")
        setSellConvertAmount("")
        changeSceduleBuyAmountForced("")
        setSellScheduleAmount("")
        clearBuyAmount('')
        clearSellAmount()
        computePrices({ buyConvertAmountP: undefined, sellConvertAmountP: undefined })
    }, [currencyList])

    useEffect(() => {

        if (buySource.id) {
            changeBuySource('', buySource.id)
            buyAvailableCurrencyR.current = get_available(buySource.id)
        }
        if (sellSourceR.current.id) {
            changeSellDestination('', Constants.USDT_CURRENCY_ID)
            sellAvailableCurrencyR.current = get_available(sellSourceR.current.id)
        }

    }, [wallet])

    const handleSelect = (key) => {
        setLastTab(prev => {
            return key !== "schedule" ? key : prev
        })
        setTab(key)
    }
    const cancelSchedule = () => {
        dispatch(cancel_schedule({ id: cancelScheduleID }))
            .then(response => {
                if (response.result === "success") {
                    toast.success(response.message)
                    setShowCancelModal(false)
                }
                else
                    toast.error(response.message)
            }).catch(err => { toast.error(err.message) })
    }

    const openCancelModal = (id) => {
        setCancelSceduleID(id)
        setShowCancelModal(true)
    }
    const clearBuyAmount = () => {
        const inp = document.querySelector("input[name=buy_currency_amount]")
        if (inp) inp.value = ""
        changeBuyAmount(0)
    }
    const clearSellAmount = () => {
        const inp = document.querySelector("input[name=sell_currency_amount]")
        if (inp) inp.value = ""
        changeSellAmount(0)
    }
    const changeBuyAmountForced = (val) => {
        const inp = document.querySelector("input[name=buy_currency_amount]")
        if (inp) inp.value = val
        setBuyConvertAmount(val)
    }
    const changeSellAmountForced = (val) => {
        const inp = document.querySelector("input[name=sell_currency_amount]")
        if (inp) inp.value = val
        setSellConvertAmount(val)
    }
    const changeSceduleBuyAmountForced = (val) => {
        const inp = document.querySelector("input[name=buy_schedule_currency_amount]")
        if (inp) inp.value = val
        setBuyScheduleAmount(val)
    }

    return (
        <>
            <Header2 />
            <Sidebar />
            <PageTitle />

            <div className="content-body buy-sell-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-5 col-lg-5 col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="buy-sell-widget">
                                        <Tabs activeKey={tab} onSelect={handleSelect} id="uncontrolled-tab-example">
                                            <Tab eventKey="buy" title="خرید">
                                                <form action="#" method="post" name="myform" className="currency_validate">
                                                    <div className="mb-3">
                                                        <label className="form-label" style={{ width: "110px" }}>بازار به :</label>
                                                        <div className="button-group">
                                                            <button type="button" className={buySource.id === Constants.USDT_CURRENCY_ID ? "active" : ""} onClick={e => changeBuySource(e, Constants.USDT_CURRENCY_ID)}>تتر</button>
                                                            <button type="button" className={buySource.id === Constants.IRT_CURRENCY_ID ? "active" : ""} onClick={e => changeBuySource(e, Constants.IRT_CURRENCY_ID)}>تومان</button>
                                                        </div>
                                                        {buyLowCreditR.current ? <Link to="/wallet" className="form-text text-muted text-nowrap pe-2">
                                                            <small className="text-danger">اعتبار ناکافی ! </small>
                                                            <small className="text-success me-2">شارژ کیف پول</small></Link> : undefined}
                                                    </div>

                                                    <div className="mb-3 d-flex align-items-center">
                                                        <label className="form-label text-nowrap ps-3" style={{ width: "110px" }}>انتخاب ارز:</label>
                                                        <Form.Control as="select" name='currency' className=" my-3 px-2 w-50" onChange={changeBuyDestination} >
                                                            <option value={undefined}>انتخاب</option>
                                                            {
                                                                currencyList && currencyList.length && currencyList.filter(i => i.is_active).map((c, idx) => {
                                                                    return (
                                                                        (buySource.small_name === 'USDT' && !["IRT", "USDT"].includes(c.small_name))
                                                                        ||
                                                                        (buySource.small_name === 'IRT' && c.small_name_slug !== "IRT")
                                                                    )
                                                                        && <option key={idx} value={c.id}> {c.name} / {buySource.name}</option>
                                                                })
                                                            }

                                                        </Form.Control>

                                                    </div>
                                                    <label className="toggle">
                                                        <input
                                                            checked={isScheduledBuy}
                                                            onChange={e => { setIsScheduledBuy(e.target.checked); setBuyConvertAmount("") }}
                                                            className="toggle-checkbox"
                                                            type="checkbox"
                                                        />
                                                        <span className="toggle-switch"></span>
                                                        <small>خرید در قیمت خاص</small>
                                                    </label>

                                                    {isScheduledBuy ?
                                                        <>
                                                            <div className="mb-3 d-flex align-items-center">
                                                                <label className="form-label  text-nowrap" style={{ width: "110px" }} ><small>خرید در قیمت:</small></label>
                                                                <div className="input-group" style={{ width: "unset" }}>
                                                                    <Input className="form-control w-50" type="number"
                                                                        onClick={e => setBuySchedulePrice("")}
                                                                        name="price" value={buySchedulePrice} onChange={changeBuySchedulePrice} />
                                                                    <div className="input-group-append p-0">
                                                                        <span className="input-group-text">{buySource.name ? buySource.name : "؟"}</span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="mb-3 d-flex align-items-center">
                                                                <label className="form-label  text-nowrap" style={{ width: "110px" }} > خرید به مقدار :</label>
                                                                <div className="input-group" style={{ width: "unset" }}>
                                                                    <Input className="form-control w-50" type="number"
                                                                        onClick={e => setBuyScheduleAmount("")}
                                                                        name="buy_schedule_currency_amount" onChange={_changeBuyScheduleAmount}
                                                                        disabled={!buySchedulePrice}
                                                                        onFocus={e => { changeSceduleBuyAmountForced("") }}
                                                                    />
                                                                    <div className="input-group-append p-0">
                                                                        <span className="input-group-text">{buyDestination.name ?? "؟"}</span>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            {(buyScheduleTimer) ? <>
                                                                <small className="w-100 d-flex align-items-center p-0 m-0 " style={{ fontSize: "12px" }}>
                                                                    <span className="ms-2">در انتظار اتمام تایپ</span>
                                                                    <Loader
                                                                        type="ThreeDots"
                                                                        height={5}
                                                                        width={15}
                                                                        color="#fff"
                                                                    ></Loader>
                                                                </small>
                                                            </>
                                                                : <small className="w-100 d-flex align-items-center p-0 m-0 " style={{ visibility: "hidden" }}>{"test "}</small>}

                                                        </>
                                                        :

                                                        <div className="mb-3">
                                                            <label className="form-label">مقدار
                                                                {buyDestination && buyDestination.id ?
                                                                    <>
                                                                        <i className="px-2"> {" "}{buyDestination.name}{" "}</i>
                                                                        مورد نظر
                                                                    </> : undefined
                                                                }
                                                            </label>

                                                            <div className="input-group position-relative">
                                                                <span className={"position-absolute icofont-close-line cursor-poitner " + (!buyConvertAmount ? "d-none" : undefined)}
                                                                    style={{ right: 0, top: "6px", fontWeight: 100, fontSize: "32px", zIndex: 1000 }} onClick={e => { clearBuyAmount() }}></span>
                                                                <Input type="text" name="buy_currency_amount" className="form-control" onFocus={e => { clearBuyAmount() }} onChange={changeBuyAmount}
                                                                    placeholder="" autoComplete="off"
                                                                />

                                                                <div className="with-sub">
                                                                    <input type="text" name="usd_amount" className="form-control " value={buyConversionResultStrR.current} readOnly
                                                                        placeholder="" />
                                                                    {buyConversionResultStrR.current ? <small className="sub">مقدار حدودی می باشد</small> : null}
                                                                </div>

                                                                {buySource.name ? <div className="input-group-append p-0">
                                                                    <span className="input-group-text">{buySource.name}</span>
                                                                </div> : undefined}
                                                                {(buyTimer) ? <>
                                                                    <small className="w-100 d-flex align-items-center p-0 m-0 " style={{ fontSize: "12px" }}>
                                                                        <span className="ms-2">در انتظار اتمام تایپ</span>
                                                                        <Loader
                                                                            type="ThreeDots"
                                                                            height={5}
                                                                            width={15}
                                                                            color="#fff"
                                                                        ></Loader>
                                                                    </small>
                                                                </>
                                                                    : <small className="w-100 d-flex align-items-center p-0 m-0 " style={{ visibility: "hidden" }}></small>
                                                                }
                                                            </div>

                                                        </div>
                                                    }

                                                    <div className=" col-xl-12 mb-3 d-flex align-items-center p-0">
                                                        <small htmlFor="currency_amount_available">موجودی :</small>
                                                        <Price className="text-success px-2 fs-5 pt-1" dir="ltr" amount={buyAvailableCurrencyR.current} postfix={buySource.small_name_slug} />
                                                        {buySource.id &&
                                                            <div className="select-all-tooltip me-4" alt="انتخاب کل موجودی" onClick={() => {
                                                                if (isScheduledBuy) {
                                                                    _changeBuyScheduleAmount(buyAvailableCurrencyR.current / +buySchedulePrice, true)
                                                                } else {
                                                                    changeBuyAmount(buyAvailableCurrencyR.current, true)
                                                                }
                                                            }}>$</div>
                                                        }
                                                    </div>
                                                    {buyConversionResultR.current ? <div className="col-12 row mb-3 mx-0 ">
                                                        <small className="d-flex justify-content-between px-0 flex-wrap">
                                                            <label className="text-nowrap">قیمت فعلی  هر واحد
                                                                <i className="px-2">{buyDestination.name}</i>
                                                                :
                                                            </label>
                                                            <span className="flex-grow-1 text-start"> <Price className="text-nowrap text-success px-2 fs-4 " amount={buyUnitPrice.current} postfix={buySource.name} /></span>
                                                        </small>
                                                    </div> : undefined}
                                                    {buyConvertErrorMessage.current.length > 0 ?
                                                        <span className="text-danger mb-3" style={{ fontSize: "12px" }}> {buyConvertErrorMessage.current} </span>
                                                        : undefined
                                                    }
                                                    <button type="button" name="submit" onClick={handleBuyConfirm}
                                                        disabled={buyConversionResultR.current === "-" || isComputing || (!buyScheduleAmount && !+buyConvertAmount) || !buyDestination.id || !buySource.id || buyLowCreditR.current || _creating_order}
                                                        className="btn btn-success w-100 d-flex justify-content-center">
                                                        {isScheduledBuy > 0 ? "ایجاد سفارش خرید" : "خرید"}
                                                        {_creating_order || (isScheduledBuy && creating_schedule) ? <Loader
                                                            type="ThreeDots"
                                                            height={25}
                                                            width={40}
                                                            color="#fff"
                                                        ></Loader> : isComputing ? <Loader
                                                            type="Circle"
                                                            height={15}
                                                            width={40}
                                                            color="#fff5"
                                                        /> : undefined}
                                                    </button>

                                                </form>
                                            </Tab>
                                            <Tab eventKey="sell" title="فروش" >
                                                <form method="post" name="myform" className="currency2_validate">
                                                    <div className="mb-3">
                                                        <label className="form-label" style={{ width: "110px" }}>بازار به :</label>
                                                        <div className="button-group">
                                                            <button type="button" className={sellDestination.id === Constants.USDT_CURRENCY_ID ? "active" : ""} onClick={e => changeSellDestination(e, Constants.USDT_CURRENCY_ID)}>تتر</button>
                                                            <button type="button" className={sellDestination.id === Constants.IRT_CURRENCY_ID ? "active" : ""} onClick={e => changeSellDestination(e, Constants.IRT_CURRENCY_ID)}>تومان</button>
                                                        </div>

                                                    </div>
                                                    <div className="mb-3 d-flex align-items-center">
                                                        <label className="form-label  text-nowrap" style={{ width: "110px" }}>انتخاب ارز: </label>
                                                        <select name='currency' className="form-control w-50 px-2 my-3" onChange={changeSellSource} >
                                                            <option value={undefined}>انتخاب</option>
                                                            {
                                                                currencyList && currencyList.length && currencyList.filter(i => i.is_active).map((c, idx) => {
                                                                    return ((sellDestination.small_name === 'USDT' && !["IRT", "USDT"].includes(c.small_name)) || (sellDestination.small_name === 'IRT' && c.small_name_slug !== "IRT")) && <option key={idx} value={c.id}> {c.name} / {sellDestination.name}</option>
                                                                })
                                                            }
                                                        </select>
                                                        {sellLowCreditR.current ? <Link to="/wallet" className="form-text text-muted text-nowrap pe-2">
                                                            <small className="text-danger">اعتبار ناکافی ! </small>
                                                            <small className="text-success me-2">شارژ کیف پول</small></Link> : undefined}

                                                    </div>

                                                    <label className="toggle">
                                                        <input
                                                            checked={isScheduledSell}
                                                            onChange={e => setIsScheduledSell(e.target.checked)}
                                                            className="toggle-checkbox"
                                                            type="checkbox"
                                                        />
                                                        <span className="toggle-switch"></span>
                                                        <small>فروش در قیمت خاص</small>
                                                    </label>

                                                    {isScheduledSell ?
                                                        <>
                                                            <div className="mb-3 d-flex align-items-center">
                                                                <label className="form-label  text-nowrap" style={{ width: "110px" }} ><small>فروش در قیمت:</small></label>
                                                                <div className="input-group" style={{ width: "unset" }}>
                                                                    <Input className="form-control w-50" type="number"
                                                                        onClick={e => setSellSchedulePrice("")}
                                                                        name="price" value={sellSchedulePrice} onChange={changeSellSchedulePrice} />
                                                                    <div className="input-group-append p-0">
                                                                        <span className="input-group-text">{sellDestination.name ? sellDestination.name : "؟"}</span>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="mb-3 d-flex align-items-center">
                                                                <label className="form-label  text-nowrap" style={{ width: "110px" }} > فروش به مقدار :</label>
                                                                <div className="input-group" style={{ width: "unset" }}>
                                                                    <Input className="form-control w-50" type="number"
                                                                        onClick={e => setSellScheduleAmount("")}
                                                                        name="amount" value={sellScheduleAmount} onChange={changeSellScheduleAmount} />
                                                                    <div className="input-group-append p-0">
                                                                        <span className="input-group-text">{sellSourceR.current.name ? sellSourceR.current.name : "؟"}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </> :
                                                        <div className="mb-3">
                                                            <label className="form-label">
                                                                مقدار
                                                                {sellSourceR.current && sellSourceR.current.id ?
                                                                    <>
                                                                        <i className="px-2"> {sellSourceR.current.name}</i>   {" "}
                                                                        مورد نظر
                                                                    </> : undefined
                                                                }</label>
                                                            <div className="input-group">
                                                                <span className={"position-absolute icofont-close-line cursor-poitner " + (!sellConvertAmount ? "d-none" : undefined)}
                                                                    style={{ right: 0, top: "6px", fontWeight: 100, fontSize: "32px", zIndex: 1000 }} onClick={clearSellAmount}></span>

                                                                <input type="text" name="sell_currency_amount" className="form-control" 
                                                                    onFocus={clearSellAmount} 
                                                                    onChange={e => changeSellAmount(e.target.value)}
                                                                    placeholder="" />
                                                                <div className=" with-sub">
                                                                    <input type="text" name="usd_amount" className="form-control" value={sellConversionResultStrR.current} readOnly
                                                                        placeholder="" />
                                                                    {sellConversionResultStrR.current ? <small className="sub">مقدار حدودی می باشد</small> : null}
                                                                </div>

                                                                {sellDestination.name ? <div className="input-group-append p-0">
                                                                    <span className="input-group-text">{sellDestination.name}</span>
                                                                </div> : undefined}
                                                            </div>

                                                        </div>
                                                    }
                                                    {(sellTimer) ? <>
                                                        <small className="w-100 d-flex align-items-center p-0 m-0 " style={{ fontSize: "12px" }}>
                                                            <span className="ms-2">در انتظار اتمام تایپ</span>
                                                            <Loader
                                                                type="ThreeDots"
                                                                height={5}
                                                                width={15}
                                                                color="#fff"
                                                            ></Loader>
                                                        </small>
                                                    </>
                                                        : <small className="w-100 d-flex align-items-center p-0 m-0 " style={{ visibility: "hidden" }}></small>
                                                    }

                                                    {/* Select all balance */}
                                                    <div className={"col-xl-12 mb-3 d-flex align-items-center p-0" + (sellSourceR.current.id ? "" : " d-none")}>
                                                        <small htmlFor="currency_amount_available">موجودی :</small>
                                                        <Price className="text-success px-2 fs-5 pt-1" dir="ltr" amount={sellAvailableCurrencyR.current} postfix={sellSourceR.current.small_name_slug} />
                                                        {sellSourceR.current.id &&
                                                            <div className="select-all-tooltip me-2" alt="انتخاب کل موجودی" onClick={() => {
                                                                changeSellAmount(sellAvailableCurrencyR.current);
                                                                setSellScheduleAmount(sellAvailableCurrencyR.current)
                                                            }}>$</div>
                                                        }
                                                    </div>
                                                    
                                                    {sellConversionResultR.current ? <div className="col-12 row mb-3 mx-0 ">
                                                        <small className="d-flex justify-content-between px-0 flex-wrap">
                                                            {sellDestination.id !== Constants.IRT_CURRENCY_ID ?
                                                                <label className="text-nowrap">قیمت فعلی  هر واحد
                                                                    <i className="px-2">{sellDestination.name}</i>
                                                                    :
                                                                    <span className="flex-grow-1 text-start"> <Price className="text-nowrap text-success px-2 fs-4 " amount={sellUnitPrice.current} postfix={sellSourceR.current.name} /> </span>
                                                                </label>
                                                                :
                                                                <label className="text-nowrap">قیمت فعلی  هر واحد
                                                                    <i className="px-2">{sellSourceR.current.name}</i>
                                                                    :
                                                                    <span className="flex-grow-1 text-start"> <Price className="text-nowrap text-success px-2 fs-4 " amount={sellSourceR.current.show_price_irt} postfix={sellDestination.name} /></span>
                                                                </label>
                                                            }

                                                        </small>
                                                    </div> : undefined}
                                                    {sellConvertErrorMessage.current.length > 0 ?
                                                        <span className="text-danger" style={{ fontSize: "12px" }}> {sellConvertErrorMessage.current} </span>
                                                        : undefined
                                                    }
                                                    <button type="button" onClick={handleSellConfirm} name="submit"
                                                        disabled={_creating_order || isComputing || (!sellScheduleAmount && !+sellConvertAmount) || !sellDestination.small_name_slug || !sellSourceR.current.small_name_slug || sellLowCreditR.current || sellConvertErrorMessage.current.indexOf('مجاز') > -1}
                                                        className="btn btn-crimson w-100 d-flex justify-content-center">
                                                        {isScheduledSell > 0 ? "ایجاد سفارش فروش" : "فروش"}
                                                        {_creating_order || (isScheduledSell && creating_schedule) ? <Loader
                                                            type="ThreeDots"
                                                            height={25}
                                                            width={40}
                                                            color="#fff"
                                                        ></Loader> : isComputing ? <Loader
                                                            type="Circle"
                                                            height={25}
                                                            width={40}
                                                            color="#fff5"
                                                        /> : undefined}</button>
                                                </form>
                                            </Tab>
                                            <Tab eventKey="schedule" title="سفارشات باز" tabClassName={"fs-13"}>
                                            </Tab>
                                        </Tabs>
                                    </div>

                                </div>
                            </div>
                            <p className="p-4">کاربر گرامی، لطفا در خرید و فروش خود نهایت دقت را داشته باشید. صرافی های اکسچنج هیچ مسئولیتی در قبال سود و زیان شما در معاملات ندارد.ضمنا توجه کنید که در روزهای پر نوسان، مقدار نمایش‌ داده شده تقریبی بوده و مقدار دقیق پس از تایید کارشناسان مشخص می‌شود.</p>
                        </div>
                        <div className="col-xl-7 col-lg-7 col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="buyer-seller" >
                                        <div className="mb-3 d-flex justify-content-left"><Timer text="تا بروزرسانی قیمت ها" /></div>
                                        <div className="d-flex flex-column mb-3 border-bottom pb-1">
                                            <div className="d-flex justify-content-between">
                                                {chartOpen ?
                                                    <><span className="fa fa-arrow-up fs-3 mb-1 cursor-pointer" onClick={e => setChartOpen(!chartOpen)}><small style={{ fontSize: "14px", marginRight: "8px" }}>بستن نمودار</small></span> </>
                                                    : <><span className="fa fa-arrow-down fs-3 mb-1 cursor-pointer" onClick={e => setChartOpen(!chartOpen)}><small style={{ fontSize: "14px", marginRight: "8px" }}>نمایش نمودار</small></span></>

                                                }
                                                <span className="text-primary">{lastChartName}</span>
                                            </div>
                                            <div dir="ltr" style={{ minHeight: 400 + "px" }} className={!chartOpen ? "d-none" : undefined}>
                                                {lastTab === "buy" && buySource.small_name_slug === "IRT" ? <IRTChart currency={buyDestination.small_name_slug} /> : undefined}
                                                {lastTab === "buy" && buySource.small_name_slug !== "IRT" ? <Chart selectedChart={lastChartName} /> : undefined}
                                                {lastTab === "sell" && sellDestination.small_name_slug === "IRT" ? <IRTChart currency={sellSourceR.current.small_name_slug} /> : undefined}
                                                {lastTab === "sell" && sellDestination.small_name_slug !== "IRT" ? <Chart selectedChart={lastChartName} /> : undefined}
                                            </div>


                                        </div>
                                        {tab === "buy" ?
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td><span className="text-primary">شما خریدار هستید</span></td>
                                                            <td><span className="text-primary">{buyBuyerAmount.current} {" "} {buyDestination.small_name_slug}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>روش پرداخت</td>
                                                            <td>{buySource.name || "-"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>کارمزد  ثابت</td>
                                                            <td><Price amount={buyFixedKarmozdR.current || 0} postfix={buyFeeUnit.current} />
                                                                <br />
                                                                <small style={{ color: "green", fontSize: "12px" }}>
                                                                    (معادل {" "}
                                                                    <Price amount={buyFixedFeeEqual.current} postfix={buyFeeUnitEqual.current} /> )
                                                                </small>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>کارمزد   تراکنش</td>
                                                            <td><Price amount={buyTransactionFee.current || 0} postfix={buyFeeUnit.current} />
                                                                <br />
                                                                <small style={{ color: "green", fontSize: "12px" }}>
                                                                    (معادل {" "} <Price amount={buyVariableFeeEqual.current} postfix={buyFeeUnitEqual.current} />)
                                                                </small>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>مجموع کارمزد</td>
                                                            <td><Price amount={buyKarmozdAmountR.current || 0} postfix={buyFeeUnit.current} />
                                                                <br />
                                                                <small style={{ color: "green", fontSize: "12px" }}>
                                                                    (معادل {" "} <Price amount={buyTotalFeeEqual.current} postfix={buyFeeUnitEqual.current} />)
                                                                </small>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>مبلغ  تراکنش</td>
                                                            <td>
                                                                <Price amount={isScheduledBuy ? buyScheduleAmount * buySchedulePrice : buyConversionResultR.current - buyKarmozdAmountR.current || 0} postfix={buySource.name} />
                                                                {
                                                                    !isScheduledBuy && buyConversionResultR.current > 0 &&
                                                                    <>
                                                                        <br />
                                                                        <small class="text-danger">
                                                                            {"+ "} <Price amount={buyKarmozdAmountR.current} postfix={" "} />
                                                                        </small>
                                                                    </>
                                                                }
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>میزان دریافتی شما</td>

                                                            <td>
                                                                <Price amount={buyFinalValue.current} postfix={buyDestination.name} />
                                                                <br />
                                                                <small style={{ color: "green", fontSize: "12px" }}>
                                                                    {buyFinalValue.current > 0 && <span>این مقدار حدودی می باشد</span>}
                                                                </small>
                                                            </td>
                                                        </tr>
                                                    </tbody>

                                                </table>
                                            </div>
                                            : tab === "sell" ?
                                                <div className="table-responsive">
                                                    <table className="table">
                                                        <tbody>
                                                            <tr>
                                                                <td><span className="text-primary">شما فروشنده هستید</span></td>
                                                                <td><span className="text-primary"><Price amount={sellBuyerAmount.current} postfix={sellSourceR.current.small_name_slug} /></span></td>
                                                            </tr>

                                                            <tr>
                                                                <td>واحد دریافتی</td>
                                                                <td>{sellDestination.name || "-"}</td>
                                                            </tr>

                                                            <tr>
                                                                <td>کارمزد  ثابت</td>
                                                                <td><Price amount={sellFixedKarmozdR.current || 0} postfix={sellSourceR.current.name} />
                                                                    <br />
                                                                    <small style={{ color: "green", fontSize: "12px" }}>
                                                                        (معادل {" "}
                                                                        <Price amount={compute_fees(sellDestination.small_name_slug, sellUnitPrice.current, sellFixedKarmozdR.current)} postfix={sellDestination.small_name_slug} />)
                                                                    </small>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>کارمزد  تراکنش</td>
                                                                <td><Price amount={sellTransactionFee.current || 0} postfix={sellSourceR.current.name} />
                                                                    <br />
                                                                    <small style={{ color: "green", fontSize: "12px" }}>
                                                                        (معادل {" "}
                                                                        <Price amount={compute_fees(sellDestination.small_name_slug, sellUnitPrice.current, sellTransactionFee.current)} postfix={sellDestination.small_name_slug} />)
                                                                    </small>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>مجموع کارمزد</td>
                                                                <td><Price amount={sellKarmozdAmountR.current || 0} postfix={sellSourceR.current.name} />
                                                                    <br />
                                                                    <small style={{ color: "green", fontSize: "12px" }}>
                                                                        (معادل {" "}
                                                                        <Price amount={compute_fees(sellDestination.small_name_slug, sellUnitPrice.current, sellKarmozdAmountR.current)} postfix={sellDestination.small_name_slug} />)
                                                                    </small>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>مبلغ  تراکنش</td>
                                                                <td><Price amount={isScheduledSell ? sellScheduleAmount : sellConvertAmount || 0} postfix={sellSourceR.current.name} />

                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td> میزان دریافتی شما</td>
                                                                <td>
                                                                    <Price amount=
                                                                        {!isScheduledSell ?
                                                                            compute_fees(sellDestination.small_name_slug,
                                                                                sellUnitPrice.current,
                                                                                sellKarmozdAmountR.current,
                                                                                sellConversionResultR.current) : sellFinalValue.current}
                                                                        postfix={sellDestination.small_name_slug} />
                                                                    <br />
                                                                    <small style={{ color: "green", fontSize: "12px" }}>
                                                                        (معادل {" "}
                                                                        <Price amount=
                                                                            {!isScheduledSell ? sellConvertAmount - sellKarmozdAmountR.current : sellFinalValueEqual.current}
                                                                            postfix={sellSourceR.current.small_name_slug} />)
                                                                    </small>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                : <div className="schedules-container">
                                                    <h5 >سفارشات باز</h5>
                                                    {schedules.length && currencyList.length ?
                                                        schedules.map((item, idx) => {

                                                            return <ScheduleItem item={item} currencyList={currencyList} openCancelModal={openCancelModal} />
                                                        })
                                                        : "مورد برای نمایش وجود ندارد"
                                                    }
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <Modal contentClassName="dark" show={showCancelModal} onHide={() => setShowCancelModal(false)} backdrop='static'>
                    <Modal.Header closeButton>
                        <Modal.Title>لغو سفارش</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>آیا از لغو این سفارش اطمینان دارید ؟</p>

                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            onClick={cancelSchedule}
                            className={"btn btn-success ps-5 pe-5 "}
                            type="button"
                        >
                            <span> تایید </span>
                        </button>
                    </Modal.Footer>

                </Modal>

            </div>


        </>
    )
}

export default BuySell;