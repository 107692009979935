export const FETCH_USER_DETAIL = "FETCH_USER_DETAIL"
export const FETCH_USER_PROFILE = "FETCH_USER_PROFILE"
export const REFRESH_USER_TOKEN = "REFRESH_USER_TOKEN"
export const CHECK_USER_SESSION = "CHECK_USER_SESSION" // check if already has a token in session
export const UPDATE_USER_DETAIL = "UPDATE_USER_DETAIL"
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE"
export const UPDATE_AUTH_ID = "UPDATE_AUTH_ID"
export const UPDATE_LOG_STATUS = "UPDATE_LOG_STATUS"
export const UPDATE_LOG_INFO = "UPDATE_LOG_INFO"
export const UPDATE_USER_WALLET = "UPDATE_USER_WALLET"
export const USER_LOGIN = "USER_LOGIN"
export const USER_LOGOUT = "USER_LOGOUT"
export const UPDATING_USER_AVATAR = "UPDATING_USER_AVATAR"

