import React from 'react'
// Convert Persian digit to English
const per2eng = s => s.replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d))
export default function Price({
    amount=0,
    multiplier=1,
    fix=true,
    postfix=" ت ",
    ...rest
}) {
    fix = false
    const[value, setValue] = React.useState(amount)
    const irt = postfix===" ت " || postfix === "تومان" || postfix === "IRT"
    React.useEffect(()=>{
        if(amount > 0){
            let t = amount
            t *=  multiplier
            if(irt){
                t = Number(Number(t).toFixed()).toLocaleString('en')
            }else{
                if(fix)
                    t = Number((Math.round(t * 100) / 100).toFixed(2)).toLocaleString();
                else
                    t = Number(t).toLocaleString("en", { maximumSignificantDigits: 6 });
            }
            setValue(t)
            // console.log(amount,Number(amount).toLocaleString(null, { maximumSignificantDigits: 5 }), t)
        }else{
            setValue(0)
        }

    }, [amount])
    return (
        <span {...rest}>
        {value} {postfix? postfix: (irt ? " ت" : "")}  
        </span>
    )
}
